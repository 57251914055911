// TODO img resource should be dynamic and put in src/assets folder
const Tile = ({item, onButtonClick}) => {

    function calculatePriceDropPercentage(current_price, previous_price) {
        return ((current_price - previous_price) / previous_price) * 100;

    }

    function displayPriceInEuros(value) {
        // Format the value as Euro currency with two decimal places
        return new Intl.NumberFormat('de-DE', {
            // style: 'currency',
            // currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    }

    return (
        <div className={"grid-item"}>
            <img className={"item-image"}
                 src={`/images/${item.cityTo}.webp`}
                 onError={
                     (e) => {
                         e.target.src = `/images/${item.countryTo.name}.webp`;
                         e.target.onerror = () => e.target.src = `/images/default_travel_pic.webp`;
                     }
                 }
                 alt={item.title}
                // style={{width: '150px', height: '150px', objectFit: 'cover'}}
            />
            <br/>

            {item.price_drop && (
                <p>{item.cityTo}<br/><b>{item.countryTo.name}</b><br/><br/>
                    <s>€{displayPriceInEuros(item.price_drop.previous_price)}</s> <br/>
                    <span className="price-new">€{displayPriceInEuros(item.price_drop.current_price)}</span>
                    ({displayPriceInEuros(calculatePriceDropPercentage(item.price_drop.current_price, item.price_drop.previous_price))}%) <br/>
                </p>
            )}
            {!item.price_drop && (
                <p>{item.cityTo}<br/><b>{item.countryTo.name}</b><br/><br/>
                    {/*TODO change below to current_price (did not work for when there is only 1 price data)*/}
                    <span className="price-new">€{displayPriceInEuros(item.fare.adults)}</span>
                </p>
            )}
            <br/>

            <button className={"detail-button-container"} onClick={() => onButtonClick(item)}>
                View Details
            </button>
        </div>
    );
};

export default Tile;