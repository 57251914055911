import React, {useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import Tile from './components/GridItem';
import FlightDetailsModal from './FlightDetailsModal';
import 'chart.js/auto';
import CountryCitySelector from "./CountryCitySelector";
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import {onAuthStateChanged, signOut} from "firebase/auth";
import {auth} from './components/firebase';
import Dashboard from "./components/Dashboard";
import NotFound from './components/NotFound';


function App() {
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:5000";

    const [user, setUser] = useState(null);
    const FLIGHTS_PER_PAGE = 50;
    const [selectedDeparturePoint, setSelectedDeparturePoint] = useState('');
    const [selectedDepartureType, setSelectedDepartureType] = useState('');
    const [showFlightDetailsModal, setShowFlightDetailsModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);  // For datepicker
    const [tileData, setTileData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedTileData, setSelectedTileData] = useState(null);
    const [differentParams, setDifferentParams] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [flightSubscriptions, setFlightSubscriptions] = useState([]);


    // TODO move this to a separate component (e.g. AuthContext.js or Login.js by passing user param or Dashboard.js)
    useEffect(() => {
        // TODO this observer is not working properly
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("auth state changed: ", user)
            if (user) {
                const uid = user.uid;
                console.log("uid:", uid, "user:", user.email)
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUser(user);
                fetchUserSubscriptions(user.email);
                // ...
            } else {
                signOut(auth).then(() => {
                    // Sign-out successful.
                    // TODO implement  signout properly
                    console.log("Signed out successfully")
                }).catch((error) => {
                    // An error happened.
                    console.log("Error signing out", error.message)
                });
            }
            // Cleanup the listener on unmount
            return () => unsubscribe();
        });
    }, [])


    const filteredItems = Array.isArray(tileData)
        ? tileData.filter(flight =>
            flight.cityTo.toLowerCase().includes(filterText.toLowerCase()) ||
            flight.countryTo.name.toLowerCase().includes(filterText.toLowerCase())
        ) : [];


    const totalPages = Math.min(Math.ceil(filteredItems.length / FLIGHTS_PER_PAGE), 10);
    const paginatedItems = filteredItems.slice(
        (currentPage - 1) * FLIGHTS_PER_PAGE,
        currentPage * FLIGHTS_PER_PAGE
    );
    const handleFilterChange = (e) => {
        setFilterText(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleRedirect = () => {
        console.log('Redirecting to Kiwi.com')
        console.log("Selected Flight Deep Link: ", selectedTileData.deep_link)
        const kiwiLink = selectedTileData.deep_link;
        window.open(kiwiLink, '_blank');
    };

    const handleCloseFlightDetails = () => setShowFlightDetailsModal(false);

    const handleSubscribeFlight = () => {
        console.log('Subscribing to flight:', selectedTileData);
        // TODO implement subscribe flight
        if (!user) {
            console.log("User not logged in, please login to subscribe to flight")
            alert("Please login to subscribe to flight")
            // navigate('/login')
            return;
        }
        const flightData = {
            flight_id: selectedTileData.id,
            departure_date: selectedTileData.local_departure,
            fly_from: selectedTileData.flyFrom,
            fly_to: selectedTileData.flyTo,
            price: selectedTileData.current_price,
            user_id: user.uid,
            user_email: user.email,
            collection_timestamp: new Date().toISOString()
        };

        console.log("Flight Data to subscribe: ", flightData)
        fetch(`${apiUrl}/api/subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(flightData),
        })
            .then(response => response.json())
            .then(data => {
                    if (data.error) {
                        console.log("Error subscribing to flight: ", data.error)
                        alert(data.error)
                        return;
                    }
                    alert("Subscribed to flight successfully")
                    flightSubscriptions.push(flightData)
                }, (error) => {
                    console.error('Error:', error);
                    alert("Flight subscription failed")
                }
            );
    }

    const handleViewDetailsButtonClick = (item) => {
        console.log('Flight Selected:', item);
        setSelectedTileData(item);
        setShowFlightDetailsModal(true);
    };

    // TODO call this function when the home page loads
    const handleGetSearchResults = () => {
        const dateString = selectedDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
        fetchFlightDeals(selectedDepartureType, selectedDeparturePoint, dateString);
    };

    const updateUser = (newValue) => {
        setUser(newValue);
    };

    function fetchUserSubscriptions(userEmail) {
        console.log('Fetching user subscriptions for user', user)
        if (userEmail) {
            fetch(`${apiUrl}/api/subscription?user_email=${userEmail}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch flight subscriptions');
                    }
                    return response.json();
                }).then(data => {
                    console.log('Flight subscriptions found:', data);
                    setFlightSubscriptions(data);
                }
            ).catch((error) => {
                console.error('Error fetching flight subscriptions:', error);
            });
        }
    }

    function fetchFlightDeals(departureType, country, date) {
        fetch(`${apiUrl}/api/price-drops?departure_type=${departureType}&country_from=${country}&departure_date=${date}`)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error("No flights deals found for " + country + " on: " + date);
                    }
                    throw new Error("An unexpected error occurred.");
                }
                return response.json();
            })
            .then(data => {
                    setTileData(data)
                    setError(null);
                    setSelectedDate(null)
                }
            ).catch(error => {
            console.error('Error getting flight results', error);
            setTileData([])
            setError(error.message);
        })
    }

    // TODO error handling for fetching greater than 10 days & 404 error handling
    useEffect(() => {
        // Fetch the user's location based on their IP address
        fetch('https://get.geojs.io/v1/ip/geo.json')
            .then(response => response.json()) // Parse the JSON from the response
            .then(data => {
                setSelectedDeparturePoint(data.country);
                const today = new Date();
                const nextWeek = new Date(today);
                nextWeek.setDate(today.getDate() + 7);
                const dateString = nextWeek.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
                fetchFlightDeals("Country", data.country, dateString)
            })
            .catch(error => {
                console.error('Error fetching the location:', error);
            })
    }, [])

    const handleDepartureChange = (departure) => {
        console.log("Selected Departure: ", departure);
        if (departure.type === "Airport") {
            setSelectedDeparturePoint(departure.code);
        } else {
            setSelectedDeparturePoint(departure.name);
        }
        setSelectedDepartureType(departure.type);
    };

    const handleFlightDateChange = (date) => {
        console.log("Selected Date: ", date);
        setSelectedDate(date);
    };

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>Latest Flight Deals</h1>
                </header>
                <nav className="navbar">
                    <div className="navbar-links">
                        <Link to="/" className="link">Home</Link>
                        {user ? <Link to="/" className="link" onClick={() => updateUser(null)}>Logout</Link> :
                            <Link to="/login" className="link">Login</Link>}

                        <Link to="/dashboard" className="link">Dashboard</Link>
                    </div>
                </nav>

                <section>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/login" element={<Login initUser={updateUser}/>}/>
                        <Route path="/dashboard" element={<Dashboard user={user} setUser={updateUser}
                                                                     flightSubscriptions={flightSubscriptions}/>}/>
                        {/* Catch-all route for undefined paths */}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </section>
                <div>
                    {/*{user ? <Dashboard user={user} setUser={updateUser} flightSubscriptions={flightSubscriptions}/> : <>*/}
                    {/*    <Home/></>}*/}

                    <br/><br/><br/>
                    <CountryCitySelector
                        selectedDeparture={selectedDeparturePoint}
                        selectedDate={selectedDate}
                        handleDepartureChange={handleDepartureChange}
                        handleDateChange={handleFlightDateChange}
                        handleGetSearchResults={handleGetSearchResults}
                        differentParams={differentParams}
                    />
                    <br/>
                </div>
                <br/><br/>

                {tileData.length > 0 ? (
                    <div className="pagination">
                        {selectedDeparturePoint ? <h3>Showing Deals for: {selectedDeparturePoint}</h3> : <h3>Loading...</h3>}

                        <input
                            type="text"
                            placeholder="Search in results..."
                            value={filterText}
                            onChange={handleFilterChange}
                        />
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={index + 1 === currentPage ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}

                    </div>

                ) : <h3>Loading Flight Deals...</h3>}

                <div className="grid-container">
                    {paginatedItems.map((item) => (

                        <Tile
                            key={item.id}
                            item={item}
                            onButtonClick={handleViewDetailsButtonClick}
                        />

                    ))}
                </div>

                <br/><br/><br/>

                {selectedTileData && (
                    <FlightDetailsModal
                        open={showFlightDetailsModal}
                        selectedTileData={selectedTileData}
                        handleClose={handleCloseFlightDetails}
                        handleSubscribe={handleSubscribeFlight}
                        handleBuyTicket={() => handleRedirect()}
                    />
                )}
                {error && (
                    <div>
                        {/*<h1>Error</h1>*/}
                        <p>{error}</p>
                    </div>
                )}
            </div>
        </Router>
    );
}

export default App;