// Dashboard.js
import React from 'react';
import {useNavigate} from 'react-router-dom';
import "./Dashboard.css";

const Dashboard = ({user, setUser, flightSubscriptions}) => {
    const navigate = useNavigate();
    const today = new Date().toISOString().split("T")[0];

    return (
        <div>
            {user ?
                <div>
                    <h2>Welcome to Flight Glimpse {user.email}</h2>
                    <h3>List of flights you are subscribed to:</h3>
                    <div className="flight-list">
                        <h3>Future Subscriptions</h3>
                        {flightSubscriptions.length === 0 ? <p>No flight subscriptions to display</p> :
                            flightSubscriptions.filter(subs => new Date(subs.departure_date) >= new Date(today)).map((subsc, index) => (
                                <div key={index} className="flight-card">
                                    <div className="flight-header">
                                        <div className="flight-route">
                                            <span>{subsc.fly_from} → {subsc.fly_to}</span>
                                        </div>
                                        {/*<div className="flight-duration">*/}
                                        {/*    Duration: {flight.duration}*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="flight-body">
                                        <div className="flight-details">
                                            <span>Price: {subsc.price}</span>
                                            {/*<span>Price: ${flight.price}</span>*/}
                                        </div>
                                        <div className="flight-time">
                                            <span>Departure: {subsc.departure_date}</span>
                                            {/*<span>Arrival: {flight.arrival}</span>*/}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    {/*TODO refactor code duplication below*/}
                    <div className="flight-list">
                        <h3>Past Subscriptions</h3>
                        {flightSubscriptions.filter(subs => new Date(subs.departure_date) < new Date(today)).length === 0 ? <p>No flight subscriptions to display</p> :
                            flightSubscriptions.filter(subs => new Date(subs.departure_date) < new Date(today)).map((subsc, index) => (
                                <div key={index} className="flight-card">
                                    <div className="flight-header">
                                        <div className="flight-route">
                                            <span>{subsc.fly_from} → {subsc.fly_to}</span>
                                        </div>
                                        {/*<div className="flight-duration">*/}
                                        {/*    Duration: {flight.duration}*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="flight-body">
                                        <div className="flight-details">
                                            <span>Price: {subsc.price}</span>
                                            {/*<span>Price: ${flight.price}</span>*/}
                                        </div>
                                        <div className="flight-time">
                                            <span>Departure: {subsc.departure_date}</span>
                                            {/*<span>Arrival: {flight.arrival}</span>*/}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                :
                <div>
                    <h2>Welcome to Flight Glimpse</h2>
                    <p>Please log in to view your monitored flights</p>
                    <button onClick={() => navigate('/login')}>Log In</button>
                </div>
            }
        </div>
    );
};

export default Dashboard;