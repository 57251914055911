import React, {useEffect, useState} from "react";
// import './CountryCitySelector.css';
import locationData from "./locations.json";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Box,
    TextField,
    Autocomplete,
    Typography
} from '@mui/material';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

const CountryCitySelector = ({
                                 selectedDeparture,
                                 selectedDate,
                                 handleDepartureChange,
                                 handleDateChange,
                                 handleGetSearchResults
                             }) => {

    const [differentParams, setDifferentParams] = useState(false);

    const toggleDiv = () => setDifferentParams(!differentParams);

    const [searchText, setSearchText] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    // Flatten the JSON structure for autocomplete
    const flattenLocations = (data) => {
        const result = [];

        for (const [country, details] of Object.entries(data)) {
            if (typeof details === "object" && !Array.isArray(details)) {
                result.push({name: country, type: "Country", code: details.code});

                for (const [city, cityDetails] of Object.entries(details)) {
                    if (city !== "code" && typeof cityDetails === "object") {
                        result.push({name: city, type: "City", code: cityDetails.code});

                        if (cityDetails.airports) {
                            cityDetails.airports.forEach((airport) => {
                                for (const [airportName, airportDetails] of Object.entries(
                                    airport
                                )) {
                                    result.push({
                                        name: airportName,
                                        type: "Airport",
                                        code: airportDetails.code,
                                    });
                                }
                            });
                        }
                    }
                }
            }
        }

        return result;
    };

    const allLocations = flattenLocations(locationData);

    const handleInputChange = (event, value) => {
        // const depart = e.target.value;
        console.log("value selected: ", value);
        // TODO handle below check better
        if (value === null || value === undefined) {
            return;
        }

        // Filter suggestions

        const filteredSuggestions = allLocations.filter((item) => {
            item.name.toLowerCase().includes(value.name.toLowerCase());
        });
        setSuggestions(filteredSuggestions);

        if (handleDepartureChange) {
            handleDepartureChange(value);
        }
        setSearchText(value);
    };

    const handleSuggestionClick = (item) => {
        setSearchText(item.name);
        // setSelected(item);
        setSuggestions([]);
    };


    return (
        <div>
            {/*<h2>Autocomplete</h2>*/}
            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={searchText}*/}
            {/*    onChange={handleInputChange}*/}
            {/*    placeholder="Search..."*/}
            {/*    style={{width: "300px", padding: "10px"}}*/}
            {/*/>*/}
            {/*<ul style={{listStyleType: "none", padding: 0}}>*/}
            {/*    {suggestions.map((item, index) => (*/}
            {/*        <li*/}
            {/*            key={index}*/}
            {/*            onClick={() => handleSuggestionClick(item)}*/}
            {/*            style={{*/}
            {/*                padding: "10px",*/}
            {/*                cursor: "pointer",*/}
            {/*                background: "#f0f0f0",*/}
            {/*                margin: "5px 0",*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {item.name} ({item.type})*/}
            {/*        </li>*/}
            {/*    ))}*/}
            {/*</ul>*/}

            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, margin: '0 auto'}}>

                <Button variant="contained" onClick={toggleDiv}>
                    {differentParams ? "Hide" : "Start"} New Search
                </Button>
                {/*<Typography variant="h6" gutterBottom>*/}
                {/*    Autocomplete with Material-UI*/}
                {/*</Typography>*/}
                {differentParams && (
                    <Autocomplete
                        // value={selectedDeparture}
                        options={allLocations}
                        getOptionLabel={(option) => `${option.name} (${option.type})`}
                        renderInput={(params) => (
                            <TextField {...params} label="Departing From" variant="outlined"/>
                        )}
                        onChange={handleInputChange}
                        // onChange={handleDepartureChange}
                    />
                )}
                {/*{differentParams && (*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <InputLabel id="dropdown1-label">Departure Country</InputLabel>*/}
                {/*        <Select*/}
                {/*            labelId="dropdown1-label"*/}
                {/*            id="dropdown1"*/}
                {/*            value={selectedCountry}*/}
                {/*            label="Option 1"*/}
                {/*            onChange={handleCountryChange}*/}
                {/*        >*/}
                {/*            {Object.keys(countriesCities).map((country) => (*/}
                {/*                <MenuItem value={country}>*/}
                {/*                    {country}*/}
                {/*                </MenuItem>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*)}*/}

                {/*    </FormControl>*/}
                {/*)}*/}

                {differentParams && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Departure Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <FormControl fullWidth>{params.input}</FormControl>}
                        />
                    </LocalizationProvider>
                )}
                {differentParams && (
                    <Button variant="contained" onClick={handleGetSearchResults}>
                        Find Deals
                    </Button>
                )}
            </Box>
        </div>
    );
}
export default CountryCitySelector;